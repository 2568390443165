import React from "react";
import QuickLoan from "../../common/Quick_Loan/Quick_Loan";
import Team from "../../common/Team/Team";
import About_png from "../../../Assets/Images/About us page-amico.png";
import img1 from "../../../Assets/logo/Indostar.png";
import img2 from "../../../Assets/logo/aaditya.png";
import img3 from "../../../Assets/logo/axis.png";
import img4 from "../../../Assets/logo/bajaj.png";
import img5 from "../../../Assets/logo/bandhan.png";
import img6 from "../../../Assets/logo/chola.png";
import img7 from "../../../Assets/logo/csb.png";
import img8 from "../../../Assets/logo/hdfc.png";
import img9 from "../../../Assets/logo/icici.png";
import img10 from "../../../Assets/logo/images.png";
import img11 from "../../../Assets/logo/india.png";
import img12 from "../../../Assets/logo/piramal.png";
import img13 from "../../../Assets/logo/poonavala.png";
import img14 from "../../../Assets/logo/tata.png";
import styles from "./About_us.module.css";
import about_us from "../../../Assets/Images/img_2.jpg";
import { ServiceDetails } from "../../Services/ServiceDetails";

function About_Us() {
  return (
    <div className="row g-0">
      <ServiceDetails />
      <div className="py-5">
        <div className="row g-0 align-items-center">
          <div className="col-sm-12 col-md-5">
            <div className="image object-center text-center">
              <img src={About_png} className={`${styles.about_img}`} alt=""/>
            </div>
          </div>
          <div className="col-sm-12 col-md-7">
            <div className={`${styles.section_title}`}>
              <h2 className={`${styles.text} my-3 fw-600 fs-2`}>
                <span className="fw-bold">About</span> {""}
                <span className="fw-bolder fs-2 text_orange">DOA</span>
              </h2>
              <p className={`${styles.width_75}`}>
                We are a renowned DSA in Maharashtra with 22 branches, 275
                employees, and partnerships with 38+ National Banks & NBFCs. Our
                focus on rural & urban loan funding has led to our presence in 5
                states. We aim to expand our reliable services PAN India while
                making a positive impact. Our unique #MissionGreen initiative
                promotes environmental conservation, and campaigns like
                "Customer First" foster financial awareness. With a strong
                reputation, we strive for excellence, ensuring a sustainable
                future as we grow and serve our customers with utmost trust and
                dedication.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        <div className="row g-0">
          <div className={`col-md-6 ${styles.cards}`}>
            <h2 className={`fw-bolder mb-4 `}>
              Get to know about <br />
              our company
            </h2>
            <div className="fs-6 lh-2">
              <h6>The DREAM Initiative</h6>
              Being part of finance sector we understand our responsibility to
              provide trusted & reliable financial service. the dream began with
              an initiative of being the cohesive component of banking sector as
              DSA in rural & emerging market over the years company has evolved
              to assume responsibility for customer service with our top notch
              professionals, deals of arya simply stands as service of the
              people by the people for the people
            </div>
          </div>
          <div className="col-md-6 d-none d-md-block">
            <img src={about_us} alt="card" style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
      </div>
      <div className="my-4">
        <Team />
      </div>
      <div className={`px-5 py-5 ${styles.section_title1}`}>
        <h2 className="text-center fw-bold text_orange pb-4">
          Our Leading Partners
        </h2>
        <div className={`${styles.slider} my-5`}>
          <div className={`${styles["slide-track"]}`}>
            <div className={`${styles.slide}`}>
              <img src={img1} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img2} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img3} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img4} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img5} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img6} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img7} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img8} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img9} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img10} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img11} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img12} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img13} alt="" />
            </div>
            <div className={`${styles.slide}`}>
              <img src={img14} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <QuickLoan />
      </div>
    </div>
  );
}

export default About_Us;
