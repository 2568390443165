import React from "react";
import Styles from "./Counter.module.css";

function Counter() {
  return (
    <div>
      <div className="row g-0">
        <div
          className={`col-sm-12 row g-0 align-items-center ${Styles.mainDiv} `}
        >
          <div className={`row g-0 py-4 py-md-0  ${Styles.bgColor}`}>
            <div className="col-sm-12 col-md-3 text-center my-4 my-md-0">
              <h2 className={`text-white ${Styles.count} fw-bolder mb-4`}>5</h2>
              <button className={`${Styles.counter_btns}`}>
                <span>Total States</span>
              </button>
            </div>
            <div className="col-sm-12 col-md-3 text-center my-4 my-md-0">
              <h2  className={`text-white ${Styles.count} fw-bolder mb-4`}>38+</h2>
              <button className={`${Styles.counter_btns}`}>
                <span>Lending Partners</span>
              </button>
            </div>
            <div className="col-sm-12 col-md-3 text-center my-4 my-md-0">
              <h2  className={`text-white ${Styles.count} fw-bolder mb-4`}>275+</h2>
              <button className={`${Styles.counter_btns}`}>
                <span>Team Members</span>
              </button>
            </div>
            <div className="col-sm-12 col-md-3 text-center my-4 my-md-0">
              <h2  className={`text-white ${Styles.count} fw-bolder mb-4`}>250Cr</h2>
              <button className={`${Styles.counter_btns}`}>
                <span>Business Value</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counter;
