import React from "react";
import financePrbImg from "../../../Assets/Images/img_2.jpg";
import credit from "../../../Assets/Images/credit_card.png";
import { Banner } from "../../common/Banner/Banner";
import classes from "../Loans.module.css";

export const Goldloan = () => {
  return (
    <div className="row g-0">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <Banner />
      </div>
      <div className={`container col-sm-12 col-md-12 col-lg-12 py-5 ${classes.container_width}`}>
        <h2 className="text-center fw-bold text_orange">Gold Loan</h2>
        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`row ${classes.criteria}`}>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-4">
                <h5 className="fw-bold mb-4 text_orange">
                  Eligibility Criteria for Gold Loan
                </h5>
                <ul className={classes.loan_criteria}>
                  <li>
                    <span>
                      <strong>Nationality:</strong> ndian citizens are eligible
                      to apply.
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Age:</strong> Applicants must be between 21 and 70
                      years old.
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Gold Purity:</strong>The gold pledged as
                      collateral should have a purity level ranging from 18K to
                      22K.
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Occupation:</strong>Individuals from various
                      backgrounds are eligible, including Salaried Employees,
                      Self-Employed Professionals, Business Owners, Farmers, and
                      Traders.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-end">
                <img src={financePrbImg} className={classes.img_width1} />
              </div>
            </div>
          </div>
        </div>

        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`row ${classes.criteria}`}>
              <div className=" px-4 col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                <img src={credit} className={classes.img_width2} />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4 mt-lg-0">
                <h5 className="fw-bold mb-4 text_orange">
                  Documents Required for Application Process
                </h5>
                <ul className={classes.loan_criteria}>
                  <li>
                    <span>
                      <strong>
                        {" "}
                        KYC documents (Any one of the following):
                      </strong>{" "}
                      Aadhar card, Voter's ID, Passport, PAN card, Driving license.
                    </span>
                  </li>

                  <li>2 Passport Size Photographs.</li>
                  <li>Agri Allied Occupation Documentation (In case of bullet repayment for agriculture customers).</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
