import React from "react";
import financePrbImg from "../../../Assets/Images/img_2.jpg";
import credit from "../../../Assets/Images/credit_card.png";
import loan from "../../../Assets/Images/002-rich.svg";
import { Banner } from "../../common/Banner/Banner";
import classes from "./Insuranceloan.module.css";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SpaIcon from "@mui/icons-material/Spa";
import QuickLoan from "../../common/Quick_Loan/Quick_Loan";

export const Insuranceloan = () => {
  return (
    <div className="row g-0">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <Banner />
      </div>
      <div
        className={`container col-sm-12 col-md-10 col-lg-10 py-5 py-5 ${classes.section_title}`}
      >
        <h2 className="text-center fw-bold text_orange">Insurance</h2>

        <section className="pt-5">
          <div>
            <div className={`bg-white  shadow ${classes.nav_border}`}>
              <div className="row">
                <div className="col-md-12 col-lg-4">
                  <div
                    className={`nav flex-column   nav-pills pb-5 ${classes.nav_pills} ${classes.nav_shadow}`}
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className={`nav-link active ${classes.nav_link} ${classes.active}`}
                      id="v-pills-health-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-health"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-health"
                      aria-selected="true"
                    >
                      <div
                        className={`d-flex align-items-center justify-content-center me-3 circle_icon_insurence ${classes.circle_icon}`}
                      >
                        {" "}
                        <HealthAndSafetyIcon />{" "}
                      </div>
                      Health Insurance
                    </button>
                    <button
                      className={`nav-link ${classes.nav_link}`}
                      id="v-pills-General-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-General"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-General"
                      aria-selected="false"
                    >
                      <div
                        className={`d-flex align-items-center justify-content-center me-3 circle_icon_insurence ${classes.circle_icon}`}
                      >
                        {" "}
                        <SpaIcon />{" "}
                      </div>
                      General Insurance
                    </button>
                    <button
                      className={`nav-link ${classes.nav_link}`}
                      id="v-pills-Life-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-Life"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-Life"
                      aria-selected="false"
                    >
                      <div
                        className={`d-flex align-items-center justify-content-center me-3 circle_icon_insurence ${classes.circle_icon}`}
                      >
                        {" "}
                        <SelfImprovementIcon />{" "}
                      </div>
                      Life Insurance
                    </button>
                  </div>
                </div>

                <div className="col-md-12 col-lg-8">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className={`tab-pane fade show pt-4 mx-4 active ${classes.active}`}
                      id="v-pills-health"
                      role="tabpanel"
                      aria-labelledby="v-pills-health-tab"
                    >
                      <div className={`d-flex ${classes.criteria}`}>
                        <div>
                          <h5 className="fw-bold mb-4 text_orange">
                            Documents Required for Health Insurence
                          </h5>
                          <ul className={classes.loan_criteria}>
                            <li>
                              <span>
                                <strong>Nationality:</strong> You should be an
                                Indian citizen.
                              </span>
                            </li>
                            <li>
                              <span>
                                <strong>Age:</strong> Minimum age requirement is
                                21 years, and the maximum age limit is 67 years.
                              </span>
                            </li>
                            <li>
                              <span>
                                <strong>Employment:</strong> You should be
                                employed with a public, private, or
                                multinational corporation (MNC).
                              </span>
                            </li>
                            <li>
                              <span>
                                <strong>CIBIL Score:</strong> Your CIBIL score
                                should be 750 or higher.
                              </span>
                            </li>
                            <li>
                              <span>
                                <strong> Monthly Salary:</strong> The minimum
                                monthly salary requirement is Rs. 22,000,
                                subject to variation based on your city of
                                residence.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade pt-4 mx-4"
                      id="v-pills-General"
                      role="tabpanel"
                      aria-labelledby="v-pills-General-tab"
                    >
                      <div className={`d-flex ${classes.criteria}`}>
                        <div>
                          <h5 className="fw-bold mb-4 text_orange">
                            Documents Required for General Insurence
                          </h5>
                          <ul className={classes.loan_criteria}>
                            <li>
                              <span>
                                <strong> KYC documents (Choose one):</strong>{" "}
                                Aadhar card, Voter's ID, Passport, PAN card.
                              </span>
                            </li>

                            <li>Salary slips of the last 3 months.</li>
                            <li>Employee ID card.</li>
                            <li>
                              Bank account statements of the last 3 months.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade pt-4 mx-4"
                      id="v-pills-Life"
                      role="tabpanel"
                      aria-labelledby="v-pills-Life-tab"
                    >
                      <div className={`d-flex ${classes.criteria}`}>
                        <div>
                          <h5 className="fw-bold mb-4 text_orange">
                            Documents Required for Life Insurence
                          </h5>
                          <ul className={classes.loan_criteria}>
                            <li>
                              <span>
                                <strong>Nationality:</strong> You should be an
                                Indian citizen.
                              </span>
                            </li>
                            <li>
                              <span>
                                <strong>Age:</strong> Minimum age requirement is
                                21 years, and the maximum age limit is 67 years.
                              </span>
                            </li>
                            <li>
                              <span>
                                <strong>Employment:</strong> You should be
                                employed with a public, private, or
                                multinational corporation (MNC).
                              </span>
                            </li>
                            <li>
                              <span>
                                <strong>CIBIL Score:</strong> Your CIBIL score
                                should be 750 or higher.
                              </span>
                            </li>
                            <li>
                              <span>
                                <strong> Monthly Salary:</strong> The minimum
                                monthly salary requirement is Rs. 22,000,
                                subject to variation based on your city of
                                residence.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <QuickLoan />
      </div>
    </div>
  );
};
