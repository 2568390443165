import React from "react";
import styles from "./Header.module.css";
import logo from "../../Assets/Images/DOA.png";
import { Link, NavLink } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";

function Header() {
  const handleChange = () => {};
  return (
    <div className={`${styles.fixed}`}>
      <nav>
        <img
          src={logo}
          className={`d-inline-block ${styles.img_logo}`}
          alt="logo"
        />

        <label htmlFor="drop" className={styles.toggle} onClick={handleChange}>
          <MenuIcon /> <span className="pt-1">Menu</span>
        </label>
        <input type="checkbox" id="drop" />
        <ul className={`${styles.menu}`}>
          <li className={`${styles.main_menu}`}>
            <NavLink to="/">Home</NavLink>
          </li>
          <li className={`${styles.main_menu}`}>
            <Link to="/about-us">About Us</Link>
          </li>
          <li className={`${styles.main_menu}`}>
            <Link to="/services">Services</Link>
          </li>
          <li className={`${styles.main_menu}`}>
            <Link to="/loan-calculator">EMI Calculator</Link>
          </li>
          <li>
            <label htmlFor="drop-1" className={styles.toggle}>
              Reach Us <KeyboardArrowDownIcon />
            </label>
            <div className={`${styles.reach_us}`}>
              Reach Us <KeyboardArrowDownIcon />
            </div>
            <input type="checkbox" id="drop-1" />
            <ul className={`${styles.submenu}`}>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
              <li>
                <Link to="/partner_program">Partner Program</Link>
              </li>
            </ul>
          </li>
          <li>
            <button className={`${styles.login_btn}`}> Login</button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
