import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ResponsiveDatePickers from "../Datepicker/Datepicker";
import classes from "./Loan_Form.module.css";
import TextField from "@mui/material/TextField";

function Loan_Form({ pageHeading }) {
  const [loanPageHeading, setLoanPageHeading] = useState("");
  useEffect(() => {
    setLoanPageHeading(pageHeading);
  }, [pageHeading]);

  return (
    <div className={`${classes.form_wrapper}`}>
      <div className="my-2">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <form>
              <div className="row g-3">
                {loanPageHeading === "Car" && (
                  <div className="col-md-6">
                    <label className="form-label">Select Person Category</label>
                    <Select fullWidth>
                      <MenuItem value="" disabled>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Salaried Person</MenuItem>
                      <MenuItem value={20}>Business Person</MenuItem>
                    </Select>
                  </div>
                )}
                <div className="col-md-6">
                  <label htmlFor="name" className="form-label">
                    Customer Name
                  </label>
                  <TextField fullWidth required id="name" name="name" />
                </div>
                {loanPageHeading === "Business" && (
                  <div className="col-md-6">
                    <label htmlFor="business_name" className="form-label">
                      Business Name
                    </label>
                    <TextField
                      fullWidth
                      required
                      name="business_name"
                      id="business_name"
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <label htmlFor="pan_no" className="form-label">
                    PAN Number
                  </label>
                  <TextField fullWidth required name="pan_no" id="pan_no" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="dob" className="form-label">
                    Date Of Birth
                  </label>
                  <ResponsiveDatePickers />
                </div>
                <div className="col-md-6">
                  <label htmlFor="loan_amt" className="form-label">
                    Required Loan Amount
                  </label>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    name="loan_amt"
                    id="loan_amt"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="mobile_no" className="form-label">
                    Mobile Number
                  </label>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    name="mobile_no"
                    id="mobile_no"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email" className="form-label">
                    E-Mail Id
                  </label>
                  <TextField
                    type="email"
                    fullWidth
                    required
                    name="email"
                    id="email"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <TextField fullWidth required name="city" id="city" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="pincode" className="form-label">
                    Pin Code
                  </label>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    name="pincode"
                    id="pincode"
                  />
                </div>
                {loanPageHeading === "Car" && (
                  <>
                    <div className="col-md-6">
                      <label htmlFor="quote_amt" className="form-label">
                        Car Quotation Amount
                      </label>
                      <TextField
                        type="number"
                        fullWidth
                        required
                        name="quote_amt"
                        id="quote_amt"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="road_price" className="form-label">
                        On Road Price
                      </label>
                      <TextField
                        type="number"
                        fullWidth
                        required
                        name="road_price"
                        id="road_price"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="ex_price" className="form-label">
                        Ex-Showroom Price
                      </label>
                      <TextField
                        type="number"
                        fullWidth
                        required
                        name="ex_price"
                        id="ex_price"
                      />
                    </div>
                  </>
                )}
                {loanPageHeading === "Business" && (
                  <>
                    <div className="col-md-6">
                      <label className="form-label">Business Type</label>
                      <Select fullWidth>
                        <MenuItem value="" disabled>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Proprietorship</MenuItem>
                        <MenuItem value={2}>Partnership</MenuItem>
                        <MenuItem value={3}>Pvt Ltd</MenuItem>
                      </Select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="business_start_date" className="form-label">
                        Business Started On
                      </label>
                      <ResponsiveDatePickers />
                    </div>
                  </>
                )}
                {loanPageHeading === "Car" && (
                  <>
                    <div className="col-md-6">
                      <label htmlFor="grossSalary" className="form-label">
                        Gross Salary
                      </label>
                      <TextField
                        type="number"
                        fullWidth
                        required
                        name="grossSalary"
                        id="grossSalary"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="netSalary" className="form-label">
                        Net Salary
                      </label>
                      <TextField
                        type="number"
                        fullWidth
                        required
                        name="netSalary"
                        id="netSalary"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="deductions" className="form-label">
                        Deductions
                      </label>
                      <TextField
                        type="number"
                        fullWidth
                        required
                        name="deductions"
                        id="deductions"
                      />
                    </div>
                  </>
                )}
                <div className="col-md-6">
                  <label htmlFor="ITR" className="form-label">
                    2 Years ITR
                  </label>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    name="ITR"
                    id="ITR"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="ternover" className="form-label">
                    Total TurnOver
                  </label>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    name="ternover"
                    id="ternover"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="net_profit" className="form-label">
                    Net Profit
                  </label>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    name="net_profit"
                    id="net_profit"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="loan_emi" className="form-label">
                    Live Loan EMI
                  </label>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    name="loan_emi"
                    id="loan_emi"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="additional_income" className="form-label">
                    Any Other Additional Income
                  </label>
                  <TextField
                    type="number"
                    fullWidth
                    required
                    name="additional_income"
                    id="additional_income"
                  />
                </div>
                {loanPageHeading === "Doctor" && (
                  <>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <label className="form-label">Hospital Type</label>
                        <Select fullWidth>
                          <MenuItem value="" disabled>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={1}>Individual</MenuItem>
                          <MenuItem value={2}>Partnership</MenuItem>
                          <MenuItem value={3}>Pvt Ltd</MenuItem>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Degree</label>
                        <Select fullWidth>
                          <MenuItem value="" disabled>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="1">MBBS</MenuItem>
                          <MenuItem value="2">MD</MenuItem>
                          <MenuItem value="3">MS</MenuItem>
                          <MenuItem value="4">MDS</MenuItem>
                          <MenuItem value="5">BAMS</MenuItem>
                          <MenuItem value="6">BHMS</MenuItem>
                          <MenuItem value="7">BDS</MenuItem>
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="registatration_date" className="form-label">
                          Registration Certificated Registered Date
                        </label>
                        <ResponsiveDatePickers />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-12 pt-4">
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <button
                        type="submit"
                        className={`w-100 fw-bold ${classes.submit_btn}`}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loan_Form;
