import { MenuItem, TextField } from "@mui/material";
import React from "react";
import classes from "./CareerForm.module.css";
import Select from "@mui/material/Select";

function CareerForm() {
  const [role, setRole] = React.useState("0");

  const handleChange = (event) => {
    setRole(event.target.value);
  };
  return (
    <div>
      <div className="row justify-content-center p-4">
        <div className="col-lg-12">
          <form>
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Full Name
                </label>
                <TextField fullWidth required id="name" name="name" />
              </div>
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Email Id
                </label>
                <TextField
                  type="email"
                  fullWidth
                  required
                  id="name"
                  name="name"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Phone Number
                </label>
                <TextField
                  type="number"
                  fullWidth
                  required
                  id="name"
                  name="name"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Role Applied
                </label>
                <Select fullWidth value={role} onChange={handleChange}>
                  <MenuItem value="0" disabled>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="1">Sales Manager (Personal Loan)</MenuItem>
                  <MenuItem value="2">Sales Manager (Home Loan)</MenuItem>
                  <MenuItem value="3">Sales Manager (Car Loan)</MenuItem>
                </Select>
              </div>
              <div className="col-md-12">
                <label htmlFor="name" className="form-label">
                  Comments
                </label>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={3}
                  placeholder="Add Comments..."
                />
              </div>
            </div>
            <div className="col-12 pt-4">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <button
                    type="submit"
                    className={`w-100 fw-bold ${classes.submit_btn}`}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CareerForm;
