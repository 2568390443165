import React from "react";
import classes from "../Loans.module.css";
import financePrbImg from "../../../Assets/Images/img_2.jpg";
import credit from "../../../Assets/Images/credit_card.png";
import { Banner } from "../../common/Banner/Banner";
import QuickLoan from "../../common/Quick_Loan/Quick_Loan";

export const ProfessionaLoan = () => {
  return (
    <div className="row g-0 bg-gray">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <Banner />
      </div>
      <div className={`container col-sm-12 col-md-12 col-lg-12 py-5 ${classes.container_width}`}>
        <h2 className="text-center fw-bold text_orange">Professional Loan</h2>
        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`${classes.criteria}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-4">
                  <h5 className="fw-bold mb-4 text_orange">
                    To be eligible for our professional loan, please meet the
                    following criteria:
                  </h5>
                  <ul className={classes.loan_criteria}>
                    <li>
                      <span>
                        <strong>Nationality:</strong> The applicant should be an
                        Indian citizen.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Age:</strong>Minimum age requirement is 21
                        years, with a maximum age limit of 60 years for salaried
                        individuals and 65 years for self-employed individuals.
                      </span>
                    </li>

                    <li>
                      <span>
                        <strong>Minimum Income:</strong>Monthly net income
                        should be Rs. 10,000 or above.
                      </span>
                    </li>

                    <li>
                      <span>
                        <strong>Work Experience:</strong> Applicants must have a
                        minimum of 2 years of working experience.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Residence Stability:</strong> Applicants should
                        have resided at their current residence for at least 1
                        year.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-end">
                  <img src={financePrbImg} className={classes.img_width1} />
                </div>
              </div>
              <div className="pt-3 ps-5">
                <div>
                  <h5 className="text_orange">
                    These are the essential criteria to be considered for our
                    auto loans.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`row ${classes.criteria}`}>
              <div className="px-4 col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                <img src={credit} className={classes.img_width2} />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4 mt-lg-0">
                <h5 className="fw-bold mb-4 text_orange">
                  Please ensure you have the following documents:
                </h5>
                <ul className={classes.loan_criteria}>
                  <li>
                    <span>
                      <strong> KYC documents (Choose one):</strong> Aadhar card,
                      Voter's ID, Passport, PAN card (Company/Firm/Individual),
                      Office ID, Any OVD proof.
                    </span>
                  </li>
                  <li>Qualification proof of the highest professional degree.</li>
                  <li>6 Months A/C Statement.</li>
                  <li>Form 16 (Past 2 years).</li>
                  <li>Latest ITR along with computation of income.</li>
                  <li>
                    Balance sheet and Profit and Loss account for the last 2
                    year.
                  </li>
                  <li>Finincial should be certified of audited by a CA.</li>
                  <li>
                    Proof of continuation(ITR/ Trade License/ Establishment
                    Certificate/ Sales Tax Certificate).
                  </li>
                  <li>
                    Sole Proprietary Declaration or Certified Copy of
                    Partnershiop Deed.
                  </li>
                  <li>
                    Director-certified true copy of Memorandum & Articles of
                    Association & Board Resolution(Original).
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12">
        <QuickLoan pageHeading="Professional" />
      </div>
    </div>
  );
};
