import React from "react";
import classes from "../Loans.module.css";
import { Banner } from "../../common/Banner/Banner";
import financePrbImg from "../../../Assets/Images/img_2.jpg";
import credit from "../../../Assets/Images/credit_card.png";
import QuickLoan from "../../common/Quick_Loan/Quick_Loan";

export const Homeloan = () => {
  return (
    <div className="row g-0 bg-gray">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <Banner />
      </div>
      <div className={`container col-sm-12 col-md-12 col-lg-12 py-5 ${classes.container_width}`}>
        <h2 className="text-center fw-bold text_orange">Home Loan</h2>
        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`${classes.criteria}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-4">
                  <h5 className="fw-bold mb-4 text_orange">
                    The eligibility criteria for housing loans for individuals
                    are as follows;
                  </h5>
                  <ul className={classes.loan_criteria}>
                    <li>
                      <span>
                        <strong>Nationality:</strong> The applicant should be an
                        Indian citizen.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Age:</strong>The minimum age requirement is 21 years, and the maximum age limit is 65 years for salaried individuals and 70 years for self-employed individuals.
                      </span>
                    </li>

                    <li>
                      <span>
                        <strong>Minimum Income:</strong>For salaried individuals, the minimum monthly income should be Rs. 15,000, and for self-employed individuals, it should be Rs. 12,000.
                      </span>
                    </li>

                    <li>
                      <span>
                        <strong>CIBIL Score:</strong> Your CIBIL score should be
                        750 or higher.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong> Co-applicant:</strong> If applicable, the co-applicant must be a family member.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-end">
                  <img src={financePrbImg} className={classes.img_width1}/>
                </div>
              </div>
              <div className="pt-3 ps-5">
                <div>
                  <h5 className="text_orange">
                  These criteria determine the eligibility for housing loans for individuals.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`row ${classes.criteria}`}>
              <div className="px-4 col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                <img src={credit} className={classes.img_width2}/>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4 mt-lg-0">
                <h5 className="fw-bold mb-4 text_orange">
                  Documents Required for Application Process
                </h5>
                <ul className={classes.loan_criteria}>
                  <li>
                    <span>
                      <strong> KYC documents (Choose one):</strong> Aadhar card,
                      Voter's ID, Passport, PAN card.
                    </span>
                  </li>

                  <li>Salary slips of the last 3 months.</li>
                  <li>Employee ID card.</li>
                  <li>Bank account statements of the last 3 months.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12">
        <QuickLoan pageHeading="Home" />
      </div>
    </div>
  );
};
