import React from "react";
import styles from "./Contact.module.css";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

const Contact = () => {
  return (
    <div>
      <section id="contact" className={`${styles.contact} py-5`}>
        <div className="container">
          <div className={`${styles.section_title}`}>
            <h2 className="text-center text_orange fw-bold">Contact</h2>
            <p className="py-2 text-secondary text-center">
              Are you having queries regarding your loan processing? Worry not!{" "}
              <br />
              Fill in this form and someone from our team will call to assist
              you with your doubts.
            </p>
          </div>

          <div className="row g-0">
            <div className="col-lg-5 d-flex align-items-stretch">
              <div className={`${styles.info}`}>
                <div className={`${styles.address}`}>
                  <LocationOnIcon />
                  <h5>Location:</h5>
                  <p>
                    <span>
                      <strong>Head Office- </strong> Deals of Arya , Flat no 18,
                      Kishor kunj , 2 nd floor,Old Sbi Colony , Pir bajar road,
                      Osmanpura, Chatrapati Sambhajinagar, Bhajivalibai Chowk,
                      Maharashtra, 431001
                    </span>
                    <br />
                    <br />
                    <span>
                      <strong>Registered Address- </strong>
                      Deals of Arya, Infront of ICICI Bank, Majalgaon, Dist.Beed,
                      Maharashtra, 431131
                    </span>
                  </p>
                </div>

                <div className={`${styles.email}`}>
                  <EmailIcon />
                  <h5>Email:</h5>
                  <p>info@dealsofarya.com</p>
                </div>

                <div className={`${styles.phone}`}>
                  <PhoneIcon />
                  <h5>Call:</h5>
                  <p>+91 7030962626</p>
                </div>

                <iframe
                  src="https://maps.google.com/maps?width=440&amp;height=300&amp;hl=en&amp;q=Deals of Arya, Arya complex, Infront of ICICI Bank, Majalgaon, Maharashtra 431131&amp;t=&amp;z=7&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  frameborder="0"
                  title="map"
                  style={{ border: 0, width: "100%", height: "290px" }}
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
              <form
               action="https://formspree.io/f/xdorznkd"
               method="POST"
                className={`${styles.php_email_form}`}
              >
                <div className="row g-0">
                  <div className="form-group col-md-6 mb-3 pe-2">
                    <label htmlFor="name">Your Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      required
                    />
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="name">Your Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="name">Message</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="10"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className={`${styles.loading}`}>Loading</div>
                  <div className={`${styles.error_message}`}></div>
                  <div className={`${styles.sent_message}`}>
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section className="site-section mt-5" id="contact-section">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-4 text-center">
                <p className="mb-4">
                  <span
                    className={`icon-room d-block h2 text-primary ${styles.icon_bg}`}
                  >
                    <LocationOnIcon className="fs-2" />
                  </span>
                  <span className="mt-4 d-block fw-bold">
                    Deals of Arya, Infront of ICICI Bank, Majalgaon, Dist.Beed
                    431131
                  </span>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <p className="mb-4">
                  <span
                    className={`icon-phone d-block h2 text-primary ${styles.icon_bg}`}
                  >
                    <PhoneIcon className="fs-2" />
                  </span>
                  <p className={`${styles.navLink} mt-4 fw-bold d-block`}>
                    +91 7030962626
                  </p>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <p className="mb-0">
                  <span
                    className={`icon-mail_outline d-block h2 text-primary ${styles.icon_bg}`}
                  >
                    <EmailIcon className="fs-2" />
                  </span>
                  <p className={`${styles.navLink} mt-4 fw-bold d-block`}>
                    info@dealsofarya.com
                  </p>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
