import React from "react";
import financePrbImg from "../../../Assets/Images/img_2.jpg";
import credit from "../../../Assets/Images/credit_card.png";
import { ServiceDetails } from "../../Services/ServiceDetails";
import QuickLoan from "../../common/Quick_Loan/Quick_Loan";
import classes from "../Loans.module.css";

export const BusinessLoan = () => {
  return (
    <div className="row g-0 bg-gray">
      <ServiceDetails />
      <div
        className={`container col-sm-12 col-md-12 col-lg-12 py-5 ${classes.container_width}`}
      >
        <h2 className="text-center fw-bold text_orange">Business Loan</h2>
        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`${classes.criteria}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-4">
                  <h5 className="fw-bold mb-4 text_orange">
                    To qualify for a business loan, individuals must meet these
                    five criteria:
                  </h5>
                  <ul className={classes.loan_criteria}>
                    <li>
                      <span>
                        <strong>Nationality:</strong> The applicant should be an
                        Indian citizen.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong>Age:</strong>The minimum age requirement is 24
                        years, and the maximum age limit is 70 years.
                      </span>
                    </li>

                    <li>
                      <span>
                        <strong>Business Vintage:</strong>The business should
                        have been in operation for at least 1 year or more.
                      </span>
                    </li>

                    <li>
                      <span>
                        <strong>CIBIL Score:</strong> Your CIBIL score should be
                        750 or higher.
                      </span>
                    </li>
                    <li>
                      <span>
                        <strong> Profitability:</strong> The business must have
                        been profitable for the past 2 years.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-end">
                  <img src={financePrbImg} className={classes.img_width1} />
                </div>
              </div>
              <div className="pt-3 ps-5">
                <div>
                  <h4>
                    In addition to the above criteria, there is an additional
                    requirement:
                  </h4>
                  <div>
                    - The applicant must own a residence, shop, office, or
                    godown.
                  </div>
                  <div>
                    - These criteria collectively determine the eligibility for
                    a business loan.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`row ${classes.criteria}`}>
              <div className="px-4 col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                <img src={credit} className={classes.img_width2} />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4 mt-lg-0">
                <h5 className="fw-bold mb-4 text_orange">
                  Documents Required for Application Process
                </h5>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="text-center text_orange">
                    <h5>Personal Documents</h5>
                  </div>
                  <ul className={classes.loan_criteria}>
                    <li>
                      <span>
                        <strong> KYC documents (Choose one):</strong> Aadhar
                        card, Voter's ID, Passport, PAN card.
                      </span>
                    </li>

                    <li>OHP- Light Bill / Tax receipt</li>
                    <li>Res Proof- Aadhar / Rent agreement </li>
                  </ul>
                </div>
                <hr />
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="text-center text_orange">
                    <h5>Shop Documents</h5>
                  </div>
                  <ul className={classes.loan_criteria}>
                    <li>Shop Act / Udyam</li>
                    <li>Shop light bill / Rent agreement</li>
                    <li>2 years ITR</li>
                    <li>GST Certificate (if applicable)</li>
                    <li>1 year statement Current A/C</li>
                    <li>Passport photo</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12">
        <QuickLoan pageHeading="Business" />
      </div>
    </div>
  );
};
