import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Styles from "./UserCard.module.css";

export const UserCard = ({ userDetails }) => {
  return (
    <div
      className={`${Styles.member} d-flex flex-column flex-md-row align-items-center align-items-md-start `}
    >
      <div className={`${Styles.pic}`}>
        <img src={userDetails?.profileImage} className="img-fluid" alt="" />
      </div>
      <div className={`${Styles.member_info}`}>
        <h4>{userDetails.name}</h4>
        <small className="fw-bold">{userDetails.designation}</small>
        <p className={Styles.text}>{userDetails.helpingText}</p>
        <div className={`${Styles.social}`}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Dealsofarya?mibextid=ZbWKwL"
          >
            <FacebookIcon />
          </a>
          <a href="/">
            <TwitterIcon />
          </a>
          <a href="/">
            <LinkedInIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://youtube.com/@dealsofarya"
          >
            <YouTubeIcon />
          </a>
        </div>
      </div>
    </div>
  );
};
