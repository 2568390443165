import React, { useEffect, useState } from "react";
import image4 from "../../../Assets/Images/loan.jpg";
import digital from "../../../Assets/Images/digital.png";
import bank from "../../../Assets/Images/bank.png";
import styles from "./DOA.module.css";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import Counter from "../../Counter/Counter";
import QuickLoan from "../../common/Quick_Loan/Quick_Loan";
import { getAllLoanDetails } from "../../../db/DBService";
import Card from "../../common/cards/card";
import Customer from "../../Customer/Customer";
import CarouselSlider from "../../Carousel/Carousel";

function DOA() {
  const [loanCollection, setLoanCollection] = useState([]);
  useEffect(() => {
    const loanCollection = getAllLoanDetails();
    setLoanCollection(loanCollection);
  }, []);

  return (
    <>
      <div>
        <CarouselSlider />

        <section id="about" className="about  py-4 py-xl-5">
          <div className="container" data-aos="fade-up">
            <div className={`text-center ${styles.section_title}`}>
              <h2>Simplify your Loan with us</h2>
              <p className={`fst-italic text-secondary m-auto py-3  ${styles.widthAdjust}`}>
                Our expert assistants play a crucial role in simplifying the
                process of loan application and ensuring a hassle-free
                experience for our clients. Here's a breakdown of what we offer:
              </p>
            </div>

            <div className="col-sm-12 pt-4">
              <div className="row g-0">
                <div className={`col-md-6 ${styles.content}`}>
                  <h4 className="fst-italic">
                    We Help Our Clients To Achieve <br />
                    Their Desired Goals
                  </h4>
                  <img
                    src={digital}
                    className="img-fluid rounded-4 mb-4 w-75"
                    alt=""
                  />
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill"></i>
                      Our first responsibility is to identify client prospects
                      and select the best loan product that aligns with their
                      financial goals and credit scores.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill"></i>
                      Once the client's application is verified and approved, we
                      oversee the entire loan process to ensure complete
                      transparency.
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className={`ps-0 ps-lg-5 ${styles.content}`}>
                    <ul>
                      <li>
                        <i className="bi bi-check-circle-fill"></i>
                        We provide lifetime assistance to our customers through
                        the repayment process, and our team of experts is always
                        available to address their concerns.
                      </li>
                      <li>
                        <i className="bi bi-check-circle-fill"></i>
                        We prioritize the safety of our clients' personal and
                        financial information and guarantee a speedy and secure
                        disbursement process.
                      </li>
                      <li>
                        <i className="bi bi-check-circle-fill"></i>
                        With our free doorstep service, clients can avoid long
                        waiting times and complete the loan application process
                        from the comfort of their own home.
                      </li>
                    </ul>

                    <div className="position-relative mt-4">
                      <img
                        src={bank}
                        className="img-fluid rounded-4 w-100 w-100 text-end"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="container-xxl py-3 py-xl-5"
          style={{ backgroundColor: "#f1f1f1" }}
        >
          <div className="container py-5">
            <div className="row g-0 feature-row">
              <div className={`text-center pb-4 ${styles.section_title}`}>
                <h6 className={`fw-bold mb-2 ${styles.offering}`}>
                  WHAT WE'RE OFFERING
                </h6>
                <h2 className="fw-bold pb-4">
                  Choose from a wide range of financial offerings
                </h2>
              </div>
              {loanCollection.map((loan) => (
                <div className="col-md-6 col-lg-4 wow fadeIn p-2 ">
                  <Card loanDetails={loan} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <Customer />

        <section className="site-section mt-5 mb-5" id="about-section">
          <div className="container pt-5">
            <div className="row">
              <div
                className="col-lg-6 mb-5"
                data-aos="fade-up"
                data-aos-delay=""
              >
                <figure className="circle-bg">
                  <img
                    src={image4}
                    alt="Free Website Template by Free-Template.co"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div
                className="col-lg-5 ms-auto px-3 px-xl-0 text-center text-md-start"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="row">
                  <div
                    className="col-12 mb-4"
                    data-aos="fade-up"
                    data-aos-delay=""
                  >
                    <div className="unit-4 d-flex">
                      <div className="unit-4-icon mr-4 mb-3">
                        <span className="text-primary flaticon-head"></span>
                      </div>
                      <div className={styles.border_bottom}>
                        <h5 className="fw-bold text_orange">Our Goal</h5>
                        <p className=" pt-2">
                          We are fully committed to meeting the financial needs
                          of the millions of hardworking Indians who are engaged
                          in informal activities. Our goal is to ensure that
                          credit is easily accessible to this segment, and we
                          are confident in our ability to achieve this
                          objective.
                        </p>
                        {/* <p className="mb-0">
                          <button className={`${styles.cta}`}>
                            <span>Read More</span>
                            <svg viewBox="0 0 13 10" height="10px" width="15px">
                              <path d="M1,5 L11,5"></path>
                              <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                          </button>
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="unit-4 d-flex">
                      <div className="unit-4-icon mr-4 mb-3">
                        <span className="text-primary flaticon-smartphone"></span>
                      </div>
                      <div className={styles.border_bottom}>
                        <h5 className="fw-bold text_orange">Our Experience </h5>
                        <p className=" pt-2">
                          we appreciate the challenges involved in running a
                          successful business. Managing customer Queries,
                          dealing with updates with our topnotch experts, the
                          list goes on and the demands and decisions are
                          continuous and stressful. As consultants, we get it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Counter />

        <section
          className="site-section pt-5"
          style={{ backgroundColor: "#f1f1f1" }}
          id="contact-section"
        >
          <div className="container py-5">
            <div className="row">
              <div className="col-md-4 text-center">
                <p className="mb-5 mb-xl-4">
                  <span
                    className={`icon-room d-block h2 text-primary ${styles.icon_bg}`}
                  >
                    <LocationOnIcon className="fs-2 fw-bold" />
                  </span>
                  <span className="mt-4 d-block fw-bold">
                    Deals of Arya, Infront of ICICI Bank, Majalgaon, Dist.Beed,
                    <br /> Maharashtra 431131
                  </span>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <p className="mb-5 mb-xl-4">
                  <span
                    className={`icon-phone d-block h2 text-primary ${styles.icon_bg}`}
                  >
                    <PhoneIcon className="fs-2 fw-bold" />
                  </span>
                  <p className={`${styles.navLink} mt-4 fw-bold d-block`}>
                    +91 7030962626
                  </p>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <p className="mb-5 mb-xl-4">
                  <span
                    className={`icon-mail_outline d-block h2 text-primary ${styles.icon_bg}`}
                  >
                    <EmailIcon className="fs-2 fw-bold" />
                  </span>
                  <p className={`${styles.navLink} mt-4 fw-bold d-block`}>
                    info@dealsofarya.com
                  </p>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <Team /> */}

        <QuickLoan />
      </div>
    </>
  );
}

export default DOA;
