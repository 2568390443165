import React from "react";
import styles from "./Footer.module.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div
        className={`container-fluid ${styles.footer} ${styles["bg-dark"]} footer py-5`}
      >
        <div className="py-2 px-2 py-sm-5 px-sm-5">
          <div className="row g-0">
            <div className={`col-lg-4 col-md-6 mb-5 mb-xl-0 pe-4  ${"footer"}`}>
              <h5 className="text-white mb-4">Head Office</h5>
              <div className="mb-3">
                <div className="d-flex">
                  <LocationOnIcon className="me-2" />
                  <span className="">
                    Deals of Arya, Flat no 18, Kishor kunj, 2nd floor, Old Sbi
                    Colony, Pir bajar road, Osmanpura, Chatrapati Sambhajinagar,
                    Bhajivalibai Chowk, Maharashtra, 431001.
                  </span>
                </div>
              </div>
              <p className="mb-3">
                <PhoneIcon className="me-2" />
                +91 7030962626
              </p>
              <p className="mb-3">
                <EmailIcon className="me-2" />
                info@dealsofarya.com
              </p>
              <div className="d-flex pt-3">
                <a
                  className="btn btn-square btn-light me-2"
                  target="_blank"
                  href="/"
                >
                  <TwitterIcon />
                </a>
                <a
                  className="btn btn-square btn-light me-2"
                  title="https://www.facebook.com/Dealsofarya?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/Dealsofarya?mibextid=ZbWKwL"
                >
                  <FacebookIcon />
                </a>
                <a
                  className="btn btn-square btn-light me-2"
                  title="https://youtube.com/@dealsofarya"
                  target="_blank"
                  rel="noreferrer"
                  href="https://youtube.com/@dealsofarya"
                >
                  <YouTubeIcon />
                </a>
                <a
                  className="btn btn-square btn-light me-2"
                  target="_blank"
                  href="/"
                >
                  <LinkedInIcon />
                </a>
              </div>
            </div>
            <div className={`col-lg-4 col-md-6 mb-5 mb-xl-0 ${"footer"}`}>
              <h5 className="text-white mb-4">Registered Address</h5>
              <p className="mb-3 d-flex">
                <LocationOnIcon className="me-2" />
                <span>
                  Deals of Arya, Infront of ICICI Bank, Majalgaon, Dist.Beed,
                  Near ICICI bank,
                  <br />
                  Maharashtra, 431131,
                </span>
              </p>
              <p className="mb-3">
                <PhoneIcon className="me-2" />
                +91 7030962626
              </p>
              <p className="mb-3">
                <EmailIcon className="me-2" />
                info@dealsofarya.com
              </p>
            </div>
            <div className={`col-lg-2 col-md-6 mb-5 mb-xl-0`}>
              <h5 className="text-white mb-4">Quick Links</h5>
              <Link
                className={`${styles.btn} ${styles["btn-link"]}`}
                to="about-us"
              >
                About Us
              </Link>
              <Link
                className={`${styles.btn} ${styles["btn-link"]}`}
                to="services"
              >
                Our Services
              </Link>
              <Link
                className={`${styles.btn} ${styles["btn-link"]}`}
                to="loan-calculator"
              >
                EMI Calculator
              </Link>
              <Link
                className={`${styles.btn} ${styles["btn-link"]}`}
                to="careers"
              >
                Careers
              </Link>
              <Link
                className={`${styles.btn} ${styles["btn-link"]}`}
                to="partner_program"
              >
                Partner Program
              </Link>
              <Link
                className={`${styles.btn} ${styles["btn-link"]}`}
                to="contact-us"
              >
                Contact Us
              </Link>
            </div>
            <div className="col-lg-2 col-md-6">
              <h5 className="text-white mb-4">Business Hours</h5>
              <p className="mb-1">Monday - Saturday</p>
              <h6 className="text-light">09:00 am - 07:00 pm</h6>
              <p className="mb-1">Sunday</p>
              <h6 className="text-light">Closed</h6>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.bottom_footer}`}>
        <div className="container">
          <p className="mb-0 text-center">
            Copyright © 2023 All Rights Reserved by DEALS OF ARYA FINSERV CONSULTANTS PRIVATE LIMITED.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
