import React from "react";
import classes from "./Customer.module.css";
import img1 from "../../Assets/Images/001-wallet.svg";
import img2 from "../../Assets/Images/002-rich.svg";
import img3 from "../../Assets/Images/003-notes.svg";
import img4 from "../../Assets/Images/004-cart.svg";
import img5 from "../../Assets/Images/005-megaphone.svg";
import img6 from "../../Assets/Images/007-piggy-bank.svg";

function Customer() {
  return (
    <div
      className={`${classes.main_div}`}
      style={{ backgroundColor: "#f1f1f1" }}
    >
      <div className={`${classes.bg_color}`}>
        <div className="row g-0 align-items-center h-100 px-3 px-md-5 py-4 py-md-0">
          <div className="col-12 col-md-6">
            <div className="px-4 text-center text-md-start">
              <h4 className="fw-bold fs-2 fs-md-1 mb-3">
                Our services have gained the trust of thousands of customers.
              </h4>
              <p style={{lineHeight: 2}}>
                We have helped thousands of customers by not letting them
                compromise on their bucket list.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row g-3">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className={`${classes.card_color_bg}`}>
                  <img src={img1} width={80} alt="" />
                  <h6>
                    Hassle-free <br /> process
                  </h6>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className={`${classes.card_color_bg_reverse}`}>
                  <img src={img2} width={80}  alt="" />
                  <h6>Low interest</h6>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className={`${classes.card_color_bg}`}>
                  <img src={img3} width={80}  alt=""/>
                  <h6>
                    Dedicated
                    <br /> experts
                  </h6>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className={`${classes.card_color_bg_reverse}`}>
                  <img src={img4} width={80}  alt=""/>
                  <h6>
                    90+ lending
                    <br /> partners
                  </h6>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className={`${classes.card_color_bg}`}>
                  <img src={img5} width={80}  alt=""/>
                  <h6>Flexible tenure</h6>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className={`${classes.card_color_bg_reverse}`}>
                  <img src={img6} width={80}  alt=""/>
                  <h6>Quick verification</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customer;
