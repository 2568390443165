import React from "react";
import Styles from "./Team.module.css";
import { UserCard } from "../UserCard/UserCard";

function Team() {
  const teamUserCollection = [
    {
      id: "1",
      name: "Mrs. Shilpa Ranjeet Rathod",
      designation: "Founder",
      helpingText:
        "DOA is a platform designed to assist you in becoming a more confident communicator. Like me from a housewife to an entrepreneur",
      profileImage: require("../../../Assets/Images/female.png"),
    },
    {
      id: "2",
      name: "Mrs. Joyti Vinod Rathod",
      designation: "Co-Founder",
      helpingText:
        "In search for brilliant heartful minds to empower we ended up in having such an amazing team around us",
      profileImage: require("../../../Assets/Images/female.png"),
    },
    {
      id: "3",
      name: "Mr. Jay U. Kolse",
      designation: "Co-Founder",
      helpingText:
        "Former Ca Experienced In Building Partnerships, Developing Strategic Visions",
      profileImage: require("../../../Assets/Images/profile1.png"),
    },
    {
      id: "4",
      name: "Mr. Sharad G. Rathod",
      designation: "CEO",
      helpingText:
        "Worked In Hospitality Engaged In Sales, Client Development And Management For Large Multinational Corporations",
      profileImage: require("../../../Assets/Images/profile2.png"),
    },
    {
      id: "5",
      name: "Mr. Sachin B. Kulkarni",
      designation: "Director",
      helpingText:
        "A seasoned professional with more than a decade of expertise in cultivating partnerships and crafting long-term strategic plans.",
      profileImage: require("../../../Assets/Images/profile1.png"),
    },
  ];
  return (
    <div className="py-5">
      <section id="team" className={`${Styles.team} section-bg}`}>
        <div className="container" data-aos="fade-up">
          <div className={`${Styles.section_title}`}>
            <h2>Team</h2>
            <p>
              Teamwork, dedication, and success are vital for achieving goals.{" "}
              <br />A collaborative effort, unwavering commitment, and
              accomplishment of <br />
              desired outcomes are all essential components.
            </p>
          </div>

          <div className="row justify-content-center">
            {teamUserCollection.map((user) => (
              <div
                className="col-lg-6 col-md-10 mb-4"
                key={user.id}
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <UserCard userDetails={user} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Team;
