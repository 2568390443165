import { Outlet } from "react-router-dom"
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header"
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop";
import StickyContact from "../Components/common/StickyContact/StickyContact";

const RootRouter = () => {
    return (
        <>
            <Header />
            <ScrollToTop />
            <main className="paddingTopHeader">
                <Outlet />
            </main>
            <Footer />
            <StickyContact/>
        </>
    )
}

export default RootRouter;