import React from "react";
import classes from "../Loans.module.css";
import financePrbImg from "../../../Assets/Images/img_2.jpg";
import credit from "../../../Assets/Images/credit_card.png";

import { Banner } from "../../common/Banner/Banner";
import QuickLoan from "../../common/Quick_Loan/Quick_Loan";

export const PersonLoan = () => {
  return (
    <div className="row g-0">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <Banner />
      </div>
      <div className={`container col-sm-12 col-md-12 col-lg-12 py-5 ${classes.container_width}`}>
        <h2 className="text-center fw-bold text_orange">Personal Loan</h2>
        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`row ${classes.criteria}`}>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-4">
                <h5 className="fw-bold mb-4 text_orange">
                  Eligibility Criteria for Personal Loan
                </h5>
                <ul className={classes.loan_criteria}>
                  <li>
                    <span>
                      <strong>Nationality:</strong> You should be an Indian
                      citizen.
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Age:</strong> Minimum age requirement is 21 years,
                      and the maximum age limit is 67 years.
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>Employment:</strong> You should be employed with a
                      public, private, or multinational corporation (MNC).
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong>CIBIL Score:</strong> Your CIBIL score should be
                      750 or higher.
                    </span>
                  </li>
                  <li>
                    <span>
                      <strong> Monthly Salary:</strong> The minimum monthly
                      salary requirement is Rs. 22,000, subject to variation
                      based on your city of residence.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-end">
                <img src={financePrbImg} className={classes.img_width1} />
              </div>
            </div>
          </div>
        </div>

        <div className="row g-0 py-4">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className={`row ${classes.criteria}`}>
              <div className="px-4 col-12 col-sm-12 col-md-12 col-lg-6 text-start">
                <img src={credit} className={classes.img_width2}/>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-4 mt-lg-0">
                <h5 className="fw-bold mb-4 text_orange">
                  Documents Required for Application Process
                </h5>
                <ul className={classes.loan_criteria}>
                  <li>
                    <span>
                      <strong> KYC documents (Choose one):</strong> Aadhar card,
                      Voter's ID, Passport, PAN card, Office ID.
                    </span>
                  </li>

                  <li>Office Letter / Light Bill</li>
                  <li>Form 16A & B</li>
                  <li>3 Months Salary Slips</li>
                  <li>6 Months A/C Statements</li>
                  <li>Loan Statements (If Any)</li>
                  <li>Passport Photo</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12">
        <QuickLoan pageHeading="Personal"/>
      </div>
    </div>
  );
};
