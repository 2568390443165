import React, { useEffect, useState } from "react";
import Styles from "./Quick_Loan.module.css";
import { useNavigate } from "react-router-dom";
import FormModal from "../../Modal/Modal";

function QuickLoan({ pageHeading }) {
  const [path, setPath] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (window?.location?.pathname) {
      let pathmatch = window.location.pathname
        .toString()
        .includes("/services/");
      let insurencePage = window.location.pathname
        .toString()
        .includes("/services/insurance");
      if (!insurencePage) {
        setPath(pathmatch);
      }
    }
  }, []);

  return (
    <div>
      <div className="row g-0">
        <div
          className={`col-sm-12 row g-0 align-items-center ${Styles.mainDiv}`}
        >
          <div className={`d-flex ${Styles.bgColor} justify-content-center`}>
            <div className="col-sm-12 col-md-12 text-center">
              <div className="row g-0">
                <div className="col-sm-12 col-md-12 mt-4">
                  <h4
                    className={`text-white fs-1 ${Styles.count} fw-bolder mb-4 mt-4`}
                  >
                    Get Quick {pageHeading || "Personal"} Loans
                  </h4>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12">
                      <span className={`text-white ${Styles.fw600}`}>
                        Get personal loans without hassle-free process
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4 g-0">
                <div className="col-sm-12 col-md-12 mb-4">
                  {path ? (
                    <FormModal pageHeading={pageHeading} />
                  ) : (
                    <button
                      className={`${Styles.counter_btns} ${Styles.cta} mb-4`}
                      onClick={() => navigate("/contact-us")}
                    >
                      <span>
                        Contact Now
                        <svg viewBox="0 0 13 10" height="10px" width="15px">
                          <path d="M1,5 L11,5"></path>
                          <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickLoan;
