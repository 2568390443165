import React from "react";
import { ServiceDetails } from "../Services/ServiceDetails";
import classes from "./Careers.module.css";
import image4 from "../../Assets/Images/grow.jpg";
import QuickLoan from "../common/Quick_Loan/Quick_Loan";
import FormModal from "../Modal/Modal";

export const Careers = () => {
  return (
    <div>
      <ServiceDetails />
      <div className="container py-5">
        <div className="row g-0 feature-row">
          <div className={`text-center pb-4 ${classes.section_title}`}>
            <h6 className={`fw-bold mb-2 ${classes.offering}`}>
              WHAT WE'RE OFFERING
            </h6>
            <h2 className="fw-bold pb-4">
              Checkout Deals Of Arya's Loans new job opportunities
            </h2>
          </div>
        </div>
        <section id="faq" className={`${classes.faq} py-5`}>
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-4">
                <div className={`px-4 ${classes.content}`}>
                  <h3>
                    Required job <strong>Positions</strong>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Duis aute irure dolor in reprehenderit
                  </p>
                </div>
                <div className="py-5 px-4">
                  {/* <button className={`${classes.counter_btns}`}>
                    <span>Apply Now</span>
                  </button> */}
                  <FormModal />
                </div>
              </div>

              <div className="col-lg-8 p-0">
                <div
                  className="accordion accordion-flush"
                  id="faqlist"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className={`${classes.accordion_item}`}>
                    <h3 className="accordion-header">
                      <button
                        className={`${classes.accordion_button} collapsed accordion-button`}
                        type="button"
                        aria-expanded="true"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-1"
                      >
                        1. Sales manager{" "}
                        <span className="text_orange">
                          &nbsp;(Personal loan)
                        </span>
                      </button>
                    </h3>
                    <div
                      id="faq-content-1"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#faqlist"
                    >
                      <div className={`${classes.accordion_body}`}>
                        <ol className={`${classes.loan_points}`}>
                          <li>
                            Responsible for personal loan growth for the
                            designated area/branch/channels
                          </li>
                          <li>
                            Maintain relationships with channel partners, and
                            customer sources and onboard new channel connectors
                          </li>
                          <li>Ensure that the monthly target is achieved</li>
                          <li>
                            Reach out to target customers (Existing and new) and
                            pitch them the benefits of the offer regarding
                            personal loan
                          </li>
                          <li>
                            Ensure smooth implementation of the process by
                            maintaining relations with cross-selling peers
                          </li>
                          <li>
                            Lead and supervise the team through proper training
                            and motivation for the implementation of the growth
                            agenda
                          </li>
                          <li>
                            Ensure adherence to laid down instructions
                            concerning the conduct of business and
                            administration by branches
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div className={`${classes.accordion_item}`}>
                    <h3 className="accordion-header">
                      <button
                        className={`${classes.accordion_button} collapsed accordion-button`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-2"
                      >
                        2. Sales manager{" "}
                        <span className="text_orange">&nbsp;(Home loan)</span>
                      </button>
                    </h3>
                    <div
                      id="faq-content-2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className={`${classes.accordion_body}`}>
                        <ol className={`${classes.loan_points}`}>
                          <li>
                            Responsible for personal loan growth for the
                            designated area/branch/channels
                          </li>
                          <li>
                            Maintain relationships with channel partners, and
                            customer sources and onboard new channel connectors
                          </li>
                          <li>Ensure that the monthly target is achieved</li>
                          <li>
                            Reach out to target customers (Existing and new) and
                            pitch them the benefits of the offer regarding
                            personal loan
                          </li>
                          <li>
                            Ensure smooth implementation of the process by
                            maintaining relations with cross-selling peers
                          </li>
                          <li>
                            Lead and supervise the team through proper training
                            and motivation for the implementation of the growth
                            agenda
                          </li>
                          <li>
                            Ensure adherence to laid down instructions
                            concerning the conduct of business and
                            administration by branches
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div className={`${classes.accordion_item}`}>
                    <h3 className="accordion-header">
                      <button
                        className={`${classes.accordion_button} collapsed accordion-button`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-3"
                      >
                        3. Sales manager
                        <span className="text_orange">&nbsp; (Car loan)</span>
                      </button>
                    </h3>
                    <div
                      id="faq-content-3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className={`${classes.accordion_body}`}>
                        <ol className={`${classes.loan_points}`}>
                          <li>
                            Responsible for driving sales and ensuring business
                            growth (Car loan sector) for designated
                            areas/branches/channels
                          </li>
                          <li>
                            Maintain strong relationships with existing and new
                            customers for repeat business or referrals
                          </li>
                          <li>
                            Provide training and deployment strategies to the
                            team to improvise the growth agenda
                          </li>
                          <li>
                            Ensure that customer requirements are fulfilled as
                            per their need
                          </li>
                          <li>
                            Keep an eye on market trends and competitor
                            intelligence to build and develop effective sales
                            and marketing strategies
                          </li>
                          <li>
                            Ensure adherence to laid down instructions
                            concerning the conduct of business and
                            administration by branches
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section id="about" className={`${classes.about} pt-3 pb-5`}>
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6">
              <h2 className="text_orange fw-bold text-center text-md-start">
                Get to know about <br /> our company
              </h2>
              <img
                src="assets/img/about.jpg"
                className="img-fluid rounded-4 mb-4"
                alt=""
              />
              <p className="text-center text-md-start">
                We are backed by a team of expert professionals with over 13
                years of experience working with more than 91 financial
                institutions across India. Our dedicated team of professional
                representatives and offices located across the country help us
                create a vast yet well-connected network spread across 29 states
                partnering with over 91 Banks/NBFCs.
              </p>
              <p className="text-center text-md-start">
                Over 50% of the Indian population belongs to the social segment
                — the “Average middle class.” It primarily consists of those
                struggling to meet their usual financial demands such as
                education costs, health expenses, and so on.
              </p>
            </div>
            <div className="col-lg-6">
              <div className={` ps-0 ps-lg-5 ${classes.contant_data}`}>
                <div className="">
                  <img src={image4} className="img-fluid rounded-4" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <QuickLoan />
    </div>
  );
}

