import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootRouter from "./Root/RootRouter";
import { Services } from "./Components/Services/Services";
import { ServiceDetails } from "./Components/Services/ServiceDetails";
import { PersonLoan } from "./Components/Loans/Persona-loan/PersonLoan";
import { ServicesRoot } from "./Components/Loans/Persona-loan/ServicesRoot";
import { BusinessLoan } from "./Components/Loans/Business-loan/BusinessLoan";
import { Homeloan } from "./Components/Loans/Home-loan/Homeloan";
import { Carloan } from "./Components/Loans/Car-loan/Carloan";
import { Goldloan } from "./Components/Loans/Gold-loan/Goldloan";
import { Insuranceloan } from "./Components/Loans/Insurance-loan/Insuranceloan";
import Loan_Calculator from "./Components/Loan_Calculator/Loan_Calculator";
import { ProfessionaLoan } from "./Components/Loans/Professional-loan/ProfessionalLoan";
import { DoctorLoan } from "./Components/Loans/Doctor-loan/DoctorLoan";
import { MortgateLoan } from "./Components/Loans/Mortgage-loan/MortgateLoan";
import DOA from "./Components/Main-Component/DOA/DOA";
import Contact from "./Components/Main-Component/Contact/Contact";
import About_Us from "./Components/Main-Component/About_Us/About_Us";
import { CreaditCardLoan } from "./Components/Loans/Credit-card-loan/CreaditCardLoan";
import { Careers } from "./Components/Careers/Careers";
import Partner_Program from "./Components/Partner_Program/Partner_Program";

function App() {
  let router = createBrowserRouter([
    {
      path: "/",
      element: <RootRouter />,
      children: [
        {
          path: "",
          element: <DOA />,
        },
        {
          path: "about-us",
          element: <About_Us />,
        },
        {
          path: "services",
          element: <Services />,
          children: [
            {
              path: "",
              element: <ServicesRoot />,
            },
            {
              path: "personal-loan",
              element: <PersonLoan />,
            },
            {
              path: "business-loan",
              element: <BusinessLoan />,
            },
            {
              path: "home-loan",
              element: <Homeloan />,
            },
            {
              path: "car-loan",
              element: <Carloan />,
            },
            {
              path: "gold-loan",
              element: <Goldloan />,
            },
            {
              path: "insurance",
              element: <Insuranceloan />,
            },
            {
              path: "car-loan",
              element: <Carloan />,
            },
            {
              path: "credit-card",
              element: <CreaditCardLoan />,
            },
            {
              path: "professional-loan",
              element: <ProfessionaLoan />,
            },
            {
              path: "doctor-loan",
              element: <DoctorLoan />,
            },
            {
              path: "mortgage-loan",
              element: <MortgateLoan />,
            },
          ],
        },
        {
          path: "services/:loanId",
          element: <ServiceDetails />,
        },
        {
          path: "loan-calculator",
          element: <Loan_Calculator />,
        },
        {
          path: "careers",
          element: <Careers />,
        },
        {
          path: "partner_program",
          element: <Partner_Program />,
        },
        {
          path: "contact-us",
          element: <Contact />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
