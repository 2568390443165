import React from "react";
import classes from "./Banner.module.css";
import { useLocation } from "react-router-dom";

export const Banner = () => {
  const params = useLocation();

  return (
    <div className="row g-0">
      <div className={classes.bg_img}>
        <div className={classes.bg_color}>
          {params.pathname === "/about-us" && (
            <span className={`${classes.banner_text}`}>
              <h2 className="fw-bold text-light display-4">About Us</h2>
              <p className="text-light fs-5">Loans that bring your dream to life</p>
            </span>
          )}
          {params.pathname === "/services" && (
            <span className={`${classes.banner_text}`}>
              <h2 className="fw-bold text-light display-4">Our Services</h2>
              <p className="text-light fs-5">
                Meet all your financial requirements with our <br /> wide range
                of loans
              </p>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
