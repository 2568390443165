import { useReducer } from "react";
import { appReducer } from "../../appreducer";
import LeftSection from "../common/leftSection";
import RightSection from "../common/rightSection";
import classes from "./Loan_Calculator.module.css";
import QuickLoan from "../common/Quick_Loan/Quick_Loan";

function Loan_Calculator() {
  const initialState = {
    principal: 1000000,
    tenure: 5,
    roi: 9,
  };

  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <div>
      <div className={`${classes["app-wrapper"]} py-5 px-3`}>
        <div className={` text-center mb-5 ${classes.section_title}`}>
          <h2 className="text_orange fw-bold mb-4">EMI Calculator</h2>
          <p className={`text-secondary m-auto ${classes.widthAdjust}`}>
            Bringing you the best and easiest-to-use EMI calculating tool that
            can help you make an informed decision on how much funding
            you need for your personal need,  how much your EMIs are
            likely to be, and other details.
          </p>
        </div>

        <div className={`${classes["app-container"]}`}>
          <LeftSection state={state} dispatch={dispatch} />
          <RightSection state={state} />
        </div>
      </div>
      <div>
        <QuickLoan />
      </div>
    </div>
  );
}

export default Loan_Calculator;
