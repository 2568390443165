import React from "react";
import classes from "./Partner_Program.module.css";
import { MenuItem, Select, TextField } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DescriptionIcon from "@mui/icons-material/Description";
import CallIcon from "@mui/icons-material/Call";
import EditIcon from "@mui/icons-material/Edit";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

function Partner_Program() {
  const [city, setCity] = React.useState("0");

  const handleChange = (event) => {
    setCity(event.target.value);
  };

  return (
    <div>
      <div className={`${classes.container}`}>
        <div className={`${classes.contact_box}`}>
          <div className={`${classes.left}`}>
            <div className="row g-0">
              <div className="col-sm-12 py-4 pe-5 ps-4">
                <div className="d-flex mb-4">
                  <span className={`${classes.left_icon}`}>
                    <EditNoteIcon className="me-3" />
                  </span>
                  <span className={`${classes.right_section}`}>
                    <p className="mb-0 fw-bold">FILL OUT ONLINE FORM</p>
                    <small>
                      Fill out your details by entering your details. Our user
                      friendly process makes it easy and convenient for you.
                    </small>
                  </span>
                </div>
                <div className="d-flex mb-4">
                  <span className={`${classes.left_icon}`}>
                    <CallIcon className="me-3" />
                  </span>
                  <span className={`${classes.right_section}`}>
                    <p className="mb-0 fw-bold">
                      RECEIVE A CALL FROM DEAL'S OF ARYA'S TEAM FOR FACE TO FACE
                      GUIDANCE
                    </p>
                    <small>
                      Once your application is receive,our financial experts get
                      in touch with you to equip you with sound knowledge of the
                      process and how we function.
                    </small>
                  </span>
                </div>
                <div className="d-flex mb-4">
                  <span className={`${classes.left_icon}`}>
                    <DescriptionIcon className="me-3" />
                  </span>
                  <span className={`${classes.right_section}`}>
                    <p className="mb-0 fw-bold">GET-PROCESS TRAINING</p>
                    <small>
                      Receive process training from our top financial experts
                      who buff you in improving do a your skills and expertise.
                    </small>
                  </span>
                </div>
                <div className="d-flex mb-4">
                  <span className={`${classes.left_icon}`}>
                    <EditIcon className="me-3" />
                  </span>
                  <span className={`${classes.right_section}`}>
                    <p className="mb-0 fw-bold">
                      SIGN YOUR BUSINESS PARTNER AGREEMENT
                    </p>
                    <small>
                      You will be asked to sign a Deal's of Aryas Business Partner
                      Agreement to legalise your partnership with us.
                    </small>
                  </span>
                </div>
                <div className="d-flex mb-4">
                  <span className={`${classes.left_icon}`}>
                    <BusinessCenterIcon className="me-3" />
                  </span>
                  <span className={`${classes.right_section}`}>
                    <p className="mb-0 fw-bold">
                      YOU ARE DEAL'S OF ARYAS BUSINESS PARTNER
                    </p>
                    <small>
                      Congratulations, you are now a Deal's of Aryas Business Partner.
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${classes.right}`}>
            <h3 className="mb-4 text_orange">Partner Program</h3>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="first_name" className="form-label">
                        First Name
                      </label>
                      <TextField
                        fullWidth
                        required
                        id="first_name"
                        name="first_name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="last_name" className="form-label">
                        Last Name
                      </label>
                      <TextField
                        fullWidth
                        required
                        id="last_name"
                        name="last_name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email Id
                      </label>
                      <TextField
                        type="email"
                        fullWidth
                        required
                        id="email"
                        name="email"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="phone" className="form-label">
                        Phone Number
                      </label>
                      <TextField
                        type="number"
                        fullWidth
                        required
                        id="phone"
                        name="phone"
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="name" className="form-label">
                        Select City
                      </label>
                      <Select fullWidth value={city} onChange={handleChange}>
                        <MenuItem value="0" disabled>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">Ahmednagar</MenuItem>
                        <MenuItem value="2">Ahmednagar</MenuItem>
                        <MenuItem value="3">Akola</MenuItem>
                        <MenuItem value="4">Amravati</MenuItem>
                        <MenuItem value="5">Aurangabad</MenuItem>
                        <MenuItem value="6">Beed</MenuItem>
                        <MenuItem value="7">Bhandara</MenuItem>
                        <MenuItem value="8">Buldhana</MenuItem>
                        <MenuItem value="9">Chandrapur</MenuItem>
                        <MenuItem value="10">Dhule</MenuItem>
                        <MenuItem value="11">Gadchiroli</MenuItem>
                        <MenuItem value="12">Gondia</MenuItem>
                        <MenuItem value="13">Hingoli</MenuItem>
                        <MenuItem value="14">Jalgaon</MenuItem>
                        <MenuItem value="15">Jalna</MenuItem>
                        <MenuItem value="16">Kolhapur</MenuItem>
                        <MenuItem value="17">Latur</MenuItem>
                        <MenuItem value="18">Mumbai City</MenuItem>
                        <MenuItem value="19">Mumbai Suburban</MenuItem>
                        <MenuItem value="20">Nagpur</MenuItem>
                        <MenuItem value="21">Nanded</MenuItem>
                        <MenuItem value="22">Nandurbar</MenuItem>
                        <MenuItem value="23">Nashik</MenuItem>
                        <MenuItem value="24">Osmanabad</MenuItem>
                        <MenuItem value="25">Palghar</MenuItem>
                        <MenuItem value="26">Parbhani</MenuItem>
                        <MenuItem value="27">Pune</MenuItem>
                        <MenuItem value="28">Raigad</MenuItem>
                        <MenuItem value="29">Ratnagiri</MenuItem>
                        <MenuItem value="30">Sangli</MenuItem>
                        <MenuItem value="31">Satara</MenuItem>
                        <MenuItem value="32">Sindhudurg</MenuItem>
                        <MenuItem value="33">Solapur</MenuItem>
                        <MenuItem value="34">Thane</MenuItem>
                        <MenuItem value="35">Wardha</MenuItem>
                        <MenuItem value="36">Washim</MenuItem>
                        <MenuItem value="37">Yavatmal</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="col-12 pt-5">
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <button
                          type="submit"
                          className={`w-100 fw-bold ${classes.submit_btn}`}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner_Program;
