import Carousel from "react-bootstrap/Carousel";
import banner1 from "../../Assets/Images/banner1.png";
import banner2 from "../../Assets/Images/banner2.png";
import banner3 from "../../Assets/Images/banner_3.png";
import banner4 from "../../Assets/Images/banner_4.png";
import banner5 from "../../Assets/Images/banner_5.png";
import classes from "./Carousel.module.css";

function CarouselSlider() {
  return (
    <Carousel>
      <Carousel.Item interval={1500}>
        <img
          src={banner2}
          className={`d-block w-100 ${classes.bg_image}`}
          alt="..."
        />
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img
          src={banner1}
          className={`d-block w-100 ${classes.bg_image}`}
          alt="..."
        />
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img
          src={banner4}
          className={`d-block w-100 ${classes.bg_image}`}
          alt="..."
        />
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img
          src={banner5}
          className={`d-block w-100 ${classes.bg_image}`}
          alt="..."
        />
      </Carousel.Item>
      <Carousel.Item interval={1500}>
        <img
          src={banner3}
          className={`d-block w-100 ${classes.bg_image}`}
          alt="..."
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselSlider;
