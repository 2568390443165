export const LOAN_DETAILS_COLLECTION = [
  {
    id: 'personal-loan',
    name: "Personal Loan",
    desc: "Need funds for addressing your immediate financial needs?",
    btn_text: "Know More",
  },
  {
    id: 'business-loan',
    name: "Business Loan",
    desc: "Excited to turn your entrepreneurial dream or business into a success?",
    btn_text: "Know More",
  },
  {
    id: 'home-loan',
    name: "Home Loan",
    desc: "Are you looking for a means to purchase your dream home?",
    btn_text: "Know More",
  },
  {
    id: 'car-loan',
    name: "Car Loan",
    desc: "Have your eyes set on a car or a vehicle?",
    btn_text: "Know More",
  },
  {
    id: 'gold-loan',
    name: "Gold Loan",
    desc: "Are you interested in obtaining a quick gold loan?",
    btn_text: "Know More",
  },
  {
    id: 'insurance',
    name: "Insurance",
    desc: "Are you looking for an attractive life, home, or car insurance policy?",
    btn_text: "Know More",
  },
  {
    id: 'credit-card',
    name: "Credit Card",
    desc: "Want to apply for a credit card but are confused given the choices available?",
    btn_text: "Know More",
  },
  {
    id: 'professional-loan',
    name: "Professional Loan",
    desc: "Do You Aspire to Achieve New Heights? Discover Our Professional Loan Options!",
    btn_text: "Know More",
  },
  {
    id: 'doctor-loan',
    name: "Doctor Loan",
    desc: "Do You Dream of Advancing Your Medical Career? Explore Our Doctor Loan!",
    btn_text: "Know More",
  },
  {
    id: 'mortgage-loan',
    name: "Mortgage Loan",
    desc: "Unlock the Door to Your Dream Home with Our Mortgage Loan Solutions!",
    btn_text: "Know More",
  },
];

export const Management = [
  {
    id: 0,
    name: "B. Ramakrishna",
    designation: "Founder CEO",
    desc: `He does not need any introduction as his name is carved in the hearts and minds
        of people by his tremendous support, hard work, dedication & hospitality. His
        career began as a sales executive who slowly climbed the ranks of the corporate
        ladder to become a business manager. The zeal for entrepreneurship coupled with
        experience in finance and philanthropic vision
        propelled his startup back in 2010 with 7 employees. His desires to make Star
        Powerz’s presence felt in all states in india.`,
  },
  {
    id: 1,
    name: "V.Venkateswara Rav",
    designation: "Founder CEO",
    desc: `Dedication and passion are the two traits that best define Star Powerz’s CEO, Mr
    B Venkateswara Rao is the driving force behind this entire operation. From
    managing the various
    departments in the company to maximizing business volumes every month, he does
    it all. As the face of the company, he acts as the interface and liaison between
    the partners and Star Powerz. Managing over 600 employees and 10,000 team
    members for sourcing funds and managing all interactions with banks.`,
  },
];
