import React, { useEffect, useState } from "react";
import classes from "./PersonLoan.module.css";
import financePrbImg from "../../../Assets/Images/img_2.jpg";
import credit from "../../../Assets/Images/credit_card.png";

import { Banner } from "../../common/Banner/Banner";
import { getAllLoanDetails } from "../../../db/DBService";
import Card from "../../common/cards/card";
import QuickLoan from "../../common/Quick_Loan/Quick_Loan";
import { ServiceDetails } from "../../Services/ServiceDetails";

export const ServicesRoot = () => {
  const [loanCollection, setLoanCollection] = useState([]);
  useEffect(() => {
    const loanCollections = getAllLoanDetails();
    setLoanCollection(loanCollections);
  }, []);

  return (
    <>
      <div>
        <ServiceDetails />
      </div>
      <div className="row g-0 feature-row bg-gray">
        <div className="text-center px-2 px-sm-5 py-5">
          <h2 className="text_orange">
            Introducing our extensive selection of offerings.
          </h2>
          <div>
            Explore our wide range of services meticulously crafted to cater to
            your diverse needs and enhance your overall well-being.
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="row justify-content-center g-0">
            <div className="col-sm-12 col-md-11 col-lg-10">
              <div className="row g-0">
                {loanCollection.map((loan) => (
                  <div className="col-md-6 col-lg-4 wow fadeIn p-2 ">
                    <Card loanDetails={loan} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <QuickLoan />
        </div>
      </div>
    </>
  );
};
